    @import url('https://fonts.googleapis.com/css2?family=Karantina:wght@300;400;700&display=swap');
    @import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
    html,input,button,textarea{font-family: "Karantina", serif;
      direction: rtl;
    }
    .circleSinger{
      height:70px;
    }
    body,html{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0;
    width:100vw;}
    .custom-google-button {
      background-color: #4285f4;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
    }
    html{
      background: #f0f0f0
    }
    .youtubeCard{
      width:100%;
      background-color: white;
          box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
          gap:30px;
          display: flex;
          flex-direction: column;
          border-radius: 10px;
          
    }
    .numberTitle{
      font-weight: 900;
      display: inline-block;
      border-bottom: 1vh solid #0b256e;
      font-size: 10vh;
      padding-right: 20px;
    }
    .youtubeInCard{
      width: auto;
      height: auto; 
    }

    .songName{font-size: 4vh;
      padding:10px 30px;
      background-color: white;
    }
    .singerName {
      font-size: 40px;
      padding: 10px;
      background-color: rgba(255, 255, 255,0.9);
      width:100%;
      text-align: center;
      color: #051a69;
      margin:0;
      min-width: 20vw;
    }
    .singerName2 {
      font-size: 30px;
      padding: 10px;
      background-color:#051b69d0;
      width:100%;
      text-align: center;
      color: white;
      margin:0;
    }

    .Cards{
      display: grid;
        grid-template-columns: 1fr 1fr ;
        gap:20px;
        box-sizing: border-box;
        padding:30px;
        width: 90vw;
        background-color: #0573ae;
      }
  
      .songsOgSinger{
        display: grid;
          grid-template-columns: 1fr  ;
          gap:0px;
          box-sizing: border-box;
          padding:30px;
          width: 90vw;        
        }
        
          
.login{
  font-size: 25px !important;
}
.MuiSlider-thumb input {
  -webkit-appearance: slider-vertical;
}
    .upWeb{
      font-size:100px;
      color:black;
      text-align: center;
      display: flex;
      width:100vw;
      justify-content: space-between;
      align-items: center;
      padding: 0px 150px;
      box-sizing: border-box;
      z-index: 50;
      background-color: rgba(255, 255, 255,0.9);
    }
    .logo{
      font-size: 90px;    
    }
    
    .upWebBG {
      background-size: cover;
      background-position:  center;
      background: radial-gradient(circle at center, #0573ae, #051a69);
      
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.7);
    }
    .Toastify__toast {
      width: -content !important;
      min-width:30vw
    }
    .openSans{
      font-family: "open sans hebrew" !important;}
      ul>li:before {
        content: '\\\\';
        position: relative;
        font-size: 1.3em;
        top: 1px;
        left: 3px;
      }
      ul {
        list-style-type: circle;
        margin:0;
        padding: 0;
      }
      
    .playlist {      
  font-family: "open sans hebrew" !important;
    position: sticky !important;
      top: 0;
      gap:20px;
      font-size: 14px;
      color: white;
      display: flex;
      align-items: center ;
      justify-content: center;
      align-self: center;
      width: 100vw;
      z-index:1000;
      background-color: #051a69;
      font-weight: 100;
      gap:30px;
    }
    .playlistDown {      
  font-family: "open sans hebrew" !important;
      gap:20px;
      font-size: 16px;
      color: white;
      display: flex;
      align-items: center ;
      justify-content: center;
      align-self: center;
      width: 100%;
      z-index:1000;
      background-color: #051b69bd;
      font-weight: 100;
      gap:30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
    }
    .playlist a{
      color:white
    }
    .showVideoBtn{
      font-size: 13px !important;
      padding:5px  !important;
      background-color: #0573ae;      
      text-align: center;
cursor: pointer;
font-weight: 500;
    }
    .centerColumn{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      }
      .centerRow{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap:10px;
        }
    .upWeb2{
    display: flex;
    justify-content: center;
    }
    .upWeb2>div{text-shadow: 2px 4px 8px rgba(0, 0, 0, 0.6);
      font-size: 10vh;
        padding: 10px 30px;
        background-color: orange;
        color:white;
        margin-bottom: 30px;transform: rotate(2deg);

    }



    .container {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      width:100%;
      overflow: hidden;
    }
    .containerFirst{
      z-index: 5;
    }
    .pointer{
      cursor: pointer;
    }
    .web {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .bigBanner{
      background-color: white;
      margin: 40px 0;
      display: flex;
      justify-content: space-between;
      font-size: 40px;
      /* background: linear-gradient(  #004981,#003e6b); */
      border-radius: 10px;
      color:black;
      flex-direction: row;
      align-items: center;
      gap:110px;
      flex-direction: row;
      width:80vw;
        border-right: 5px solid orange;
        overflow: hidden;
        padding:0 40px 0  0 ;
        font-weight: 300;
        letter-spacing: 0.5px;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.3);
    }
    .atzbaha{
      background-color: #0573ae;
      height:0px;
      display: flex;
      flex-direction: row;
        align-items: center;
        padding: 30px;
        color:white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }

    .or{
      background-color: orange;
      color:white;
      height: 60px;
      width:60px;display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size:40px;
      transform: skewX(-15deg);
      font-weight: 500;text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }
    .vs{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap:10px;
      align-items: center;
      
    }

    .image-container {
      position: relative;
      overflow: hidden;
    }
    
    .image-container img {
      filter: grayscale(100%); /* הפיכת התמונה לאפורה */
    }
    .image-container-singer {
      position: relative;
     width:1000px
    }
    .image {
      width: 900px;
      height: auto;
      opacity: 0.7;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: start;
      gap:20px;
      align-items: center;
      flex-direction: column;
    }
    .overlaySinger {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: end;
      gap: 20px;
      align-items: center;
      flex-direction: column;
    }

    
    .play-button2 {
      position: absolute; /* מיקום אבסולוטי במרכז התמונה */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* מרכוז האלמנט */
      border-radius: 50%;
      background-color: #e75d00;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:10px 10px 10px 20px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    }
    .play-button {

      border-radius: 50%;
      background-color: #e75d00;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:2px 2px 2px 4px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    }

    .play-button::before {
      content: "\25BA";
      /* סמל ה-play */
      font-size: 12px;
      color: white;
    }
    .play-button2::before {
      content: "\25BA";
      /* סמל ה-play */
      font-size: 80px;
      color: white;
    }
    .singleRow{
      overflow: hidden;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      background-color: #0573ae;
    }
    .singleRowSingers{
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      height:150px;
      background-size:  130% ;
      background-position: right;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content:end;
      transition: all 1s ease-in-out;
      width:100%;
      max-width: 80vw !important;
        }
        .bestSinger{
         
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      height:230px;
      background-size:  150% ;
      background-position: center;
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content:end;
      transition: all 1s ease-in-out;
      width:100%

        }
        .singleRowSingers:hover{
      background-size:  120% ;
      background-position: left;
        }
        .bestSinger:hover{
          background-size:  120% ;
          background-position: left;
            }

    form{
      font-size: 30px;
    }

    .showMore{
      width:300px;
      padding:10px ;
      background-color: #0573ae;
      color:white;
      font-size: 35px;
      text-align: center;
      margin-top:35px;
      border-radius: 20px;
      cursor: pointer;
    }
    .singers{
    display: grid;
      grid-template-columns: 1fr  1fr 1fr;
      gap: 20px;
      margin: 0;
      padding: 0%;
    }

    .singerHero{
      background-color: #0573ae;
      height:30vh;
    }
    .Breadcrumb{
      font-size: 25px;
      margin: 30px;
    }
    .pageTitle{
      font-size:45px;
      margin-right: 30px;
    }

    .userImage{
      height:40px;
      border-radius: 50%;
    }
    .row{
      display: flex;
      gap:10px
    }
    .loginButton{
      font-family: "open sans hebrew" !important;
      font-size: 20px !important;
      background:linear-gradient(45deg, #e75d00 0,  #FD6F01 , #FFB000);
      color: white;
      cursor: pointer;
      border:none;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      padding: 5px 20px;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
    }
    .bottomMSG{
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      background-color: white;
      width: 60vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-bottom: 30px;
      margin: 60px 0;
    }
    .bottomTitle{
      background-color: #051a69;
      color: white;
      font-size: 30px;
      padding:20px 30px;
      width: 100%;
    }
    
    .addSong{
      background-color: white;
      padding:30px;
      margin:30px;
      font-size:20px;
    }
    .bottomTextarea{
      background-color: white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
      border:1px solid #051a69;
      padding:10px;
      font-size: 28px;
      font-weight: 100;
      width:90%

    }
    .bottomBtn{
      background-color: #051a69;
      color: white;
      padding: 10px;
      border:none;
      font-size :25px;
      width:100px;
      margin:0;
      resize: none;
      cursor: pointer;

    }
    .loginButtonRow{
      background-color: white;
      display: flex;
      justify-content: end;
      padding: 10px;
    }
    .flexToEnd{
      width:90%;
      display: flex;
      justify-content: end;
      padding: 0;
    }
    .hero{
      width:100vw;
      height:80vh;
      background-attachment: fixed;

      background-size: cover;
      background-position: center;
    }
    .hero>div {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom , rgba(255, 255, 255,0),  rgba(255, 255, 255,0.7 ) 60%,#f0f0f0 100%);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 9vh;
      flex-direction: column;
    }
    .bigTitle{
      font-size: 70px !important;
      font-weight:900;
color: #FD6F01;
background-color: rgba(255,255,255,0.5);
padding:10px;
      
    }


    .searchTabs{
      display: flex;
      background-color:rgba(231, 93, 0, 0.5);
      border-radius: 30px;
      font-size: 30px;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
    }
    .searchTabsActive{
      background-color: white;
      border-radius: 30px;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.4);
          padding: 5px 20px;
      cursor: pointer;
    }    .searchTabsinactive{
      border-radius: 30px;
      padding: 5px 20px;
      cursor: pointer;
    }

    .searchBox{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap:0
    }
    .searchBtnRow{
      display: flex;
      width:600px;
      max-width: 80vw !important;
      font-size: 30px;
      justify-content: space-between;
      align-items: center;
      margin-top:30px;
      gap:0;
      height: 50px;
      box-shadow: 2px 4px 8px rgba(0, 4, 7, 0.3);
      border-radius: 30px;
      overflow: hidden;
      background-color: white;
    }
    
    h1{
      font-size: 50px;
    }
    h2{
      font-size: 40px;
    }
    h3{
      font-size: 30px;
    }
    .bigPlaylist{
      display: grid;      
      grid-template-columns: 1fr 4fr ;
      height: 100%;
      background-color: #051a69;
    }
    .bigPlaylistList{      
      overflow-y: auto; 
      overflow-x
      : hidden;
      background-color: white;
      height: calc(100vh - 94px);

    }
    .songRow{
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      font-size: 28px;
      align-items: center;
      padding: 5px 5px 5px 10px ;
      justify-content: space-between;
      background-color: white;
    }
    .songRowActive{
      background-color: #051a69;
      color:white;
          box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      font-size: 28px;
      align-items: center;
      padding: 5px 5px 5px 10px ;
      justify-content: space-between;
    }
    
    
    .playlistSongRow{
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      font-size: 12px;
      align-items: center;
      padding: 5px 5px 5px 10px ;
      justify-content: space-between;
      background-color: white;
      font-family: 'open sans hebrew';
      letter-spacing: 0px;
    }
    .overflowEllipsis{      
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
    }
    .playlistSongRowActive{
      background-color: #051a69;
      color:white;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      font-size: 12px;
      align-items: center;
      padding: 5px 5px 5px 10px ;
      justify-content: space-between;
      font-family: 'open sans hebrew';
      letter-spacing: 0px;
    }
    
    .playlistSongRow2{
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      display: grid;
      margin-top:10px;
      grid-template-columns: 1fr  10fr;
      font-size: 16px;
      justify-content: space-between;
      background-color: white;
    }
    .playlistSongRowImg{
      height:40px
    }
    .searchBtnInput{
      outline: none;
      font-size: 30px;
      background-color: white ;
      border: white;
      height:100%;
      margin:0;
      padding-right: 30px ;
      width:100%
    }
    
    .searchBtn{
      background-color: #051a69;
      display: flex;
      height: 50px;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      color: white;
      height:100%;
      padding: 0px 40px;
         cursor: pointer;
    }
    .txtUnderSearch{
      font-size: 25px;
      margin-top:10px;
      display: flex;
      gap:20px;

  text-decoration: underline;
    }

    *:link{
      text-decoration:none
    } 
    *{
      box-sizing: border-box !important;
      max-width: 100% !important;
    }

    .navigation{
      font-size: 35px;
      display: flex;
    }
    .navigation a{
      color:black
    }
    .navigation>div{
padding:0 10px;
border-right:1px solid grey ;
transition: 0.5s all ease-in-out;
cursor: pointer;
font-weight: 500;
    }
    .navigation>div:hover{
      padding:0 20px;
      border-right:1px solid grey ;
      background-color: #f0f0f0;
          }

          a{color:black;
            text-decoration:none;
          }.activeNavigation{
            background-color: #f0f0f0 !important;
            font-weight: 700 !important;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
          }

          .playlistImg{
            height:60px;
            width:auto;
          }

          
          .songPageImg{
            height:400px;
            width:auto;
          }

          .btnAllSingSongs{
            display: inline-block;
            padding: 5px 20px;
            background-color: #051a69;
            color:white;
            font-size: 25px;
            font-weight: 300;
            margin-top: 5px;
            box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
            cursor: pointer;
          }

          .smallPlaylists{
            font-family: 'open sans hebrew';
            background:#051a69;
            color:white;
               display:inline-block;
               padding:5px 10px;
               font-size:12px;
                border-radius:10px;
                cursor: pointer;
          }
   
          
          .addPlaylists{
            background:#fd4801;
            margin-top:5px;
            color:white;
               display:inline-block;
               padding:5px 10px;
               font-size:12px;
                border-radius:10px;
                margin:0px;
            font-family: 'open sans hebrew';
                cursor: pointer;
          }
          .smallPlay{
            background:#051a69;
               font-size:12px;
               cursor: pointer;
                color:white;
               display:flex;
               padding:5px 10px;
                border-radius:10px;
                margin:5px;
                gap:10px;                
  width: min-content;
  font-family: 'open sans hebrew';
  align-items: center;
          }

          .songContainer{
            background-color: white;
          box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
           
            width:80vw;
          }

        
          

.loader-overlay {
  position: fixed; /* כדי לכסות את כל המסך */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* רקע לבן עם 90% אופקיות */
  display: flex;
  justify-content: center; /* מרכוז אנכי */
  align-items: center; /* מרכוז אופקי */
  z-index: 9999; /* כדי להבטיח שהוא מעל כל התוכן */
}

.loader-content {
  display: flex;
}

.loader-text {
  font-size: 24px;
  margin-bottom: 20px;
}

.loader-circle {
  width: 70px;
  height: 70px;
  border-radius: 50%; /* עיגול */
  animation: rotate 1s linear infinite; /* אנימציה של סיבוב */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
    border: 5px solid #051a69; /* גבול כחול */
    border-top-color: transparent; /* כדי ליצור אפקט טעינה */
    
  }
  100% {
    transform: rotate(360deg);
    
  border: 7px solid #051a69; /* גבול כחול */
  border-top-color: transparent; /* כדי ליצור אפקט טעינה */
  }
}

@media (max-width: 768px) {
  .logo {
font-size: 90px;
  }
  
  .upWeb{
    flex-direction: column-reverse;
    padding: 0;
  }
  
  .navigation{
    font-size: 23px;
  }
  .loginButton{
    padding:5px 17px;
    margin:5px;
  }
  
  .playlist {      
    font-family: "open sans hebrew" !important;
      position: sticky !important;
        top: 0;
        align-items: center;
        justify-content: center;
      }

      .mobileHide{
        display: none !important;
      }
      .playlist>div{
        
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        gap:10px;
        padding:0;
      }
 
      
    .playlistSongRowImg{
      height:50px
    }
    
    .playlistSongRow{
      font-family: 'open sans hebrew';
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);
      display: flex;
      font-size: 16px;
      align-items:start;
      padding: 5px 5px 5px 10px ;
      justify-content:end;
      background-color: white;
      flex-direction: column;}
      
      .singers{
      
          grid-template-columns: 1fr  ;
        }

    
}
.rowToClumn{
  display: flex;
  gap:10px;
  align-items: center;
}
.equalizer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 10rem;
}

.equalizer-bar {
  width: 1.8rem;
  margin: 0 3px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  height: 100%;
  background: linear-gradient(to top, #000, rgba(255,255,255,0.1));
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.equalizer-bar span {
  display: block;
  height: 4px;
  border-radius: 2px;
  width: 100%;
  margin: 2px 0;
  transition: opacity 0.1s ease, height 0.1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.equalizer-bar span:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.7);
}

.equalizer-bar span:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.9);
}
.cp {
position: fixed;
bottom:3%;
right:3%;
color:#fff;
text-decoration:none;
}
.playerContainer {
  position: relative; /* כדי לאפשר מיקום אבסולוטי של ה-DIV השני */
  height: 100%; /* או גובה אחר שתרצה */
}
.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* стиלים נוספים */
}

.hide{
  display: none !important;
}